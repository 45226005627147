@font-face {
  font-family: 'ap';
  src:  url('../fonts/ap.eot?lpge4a');
  src:  url('../fonts/ap.eot?lpge4a#iefix') format('embedded-opentype'),
    url('../fonts/ap.ttf?lpge4a') format('truetype'),
    url('../fonts/ap.woff?lpge4a') format('woff'),
    url('../fonts/ap.svg?lpge4a#ap') format('svg');
  font-weight: normal;
  font-style: normal;
}

.-ap {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ap' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-uniF1D8:before {
  content: "\f1d8";
}
.icon-uniF1D0:before {
  content: "\f1d0";
}
.icon-uniF1D7:before {
  content: "\f1d7";
}
.icon-uniF1DE:before {
  content: "\f1de";
}
.icon-uniF183:before {
  content: "\f183";
}
.icon-uniF114:before {
  content: "\f114";
}
.icon-uniF156:before {
  content: "\f156";
}
.icon-uniF12C:before {
  content: "\f12c";
}
.icon-uniF11A:before {
  content: "\f11a";
}
.icon-uniF1AB:before {
  content: "\f1ab";
}
.icon-uniF10D:before {
  content: "\f10d";
}
.icon-uniF10F:before {
  content: "\f10f";
}
.icon-uniF101:before {
  content: "\f101";
}
.icon-uniF162:before {
  content: "\f162";
}
.icon-uniF15F:before {
  content: "\f15f";
}
.icon-uniF152:before {
  content: "\f152";
}
.icon-uniF149:before {
  content: "\f149";
}
.icon-uniF1AA:before {
  content: "\f1aa";
}
.icon-uniF111:before {
  content: "\f111";
}
.icon-uniF104:before {
  content: "\f104";
}
.icon-uniF193:before {
  content: "\f193";
}
.icon-uniF195:before {
  content: "\f195";
}
.icon-uniF196:before {
  content: "\f196";
}
.icon-uniF197:before {
  content: "\f197";
}
.icon-uniF198:before {
  content: "\f198";
}
.icon-uniF107:before {
  content: "\f107";
}
.icon-uniF13E:before {
  content: "\f13e";
}
.icon-uniF150:before {
  content: "\f150";
}
.icon-uniF148:before {
  content: "\f148";
}
.icon-uniF172:before {
  content: "\f172";
}
.icon-uniF14D:before {
  content: "\f14d";
}
.icon-uniF186:before {
  content: "\f186";
}
.icon-uniF127:before {
  content: "\f127";
}
.icon-uniF14B:before {
  content: "\f14b";
}
.icon-uniF1272:before {
  content: "\f128";
}
.icon-uniF12F:before {
  content: "\f12f";
}
.icon-uniF100:before {
  content: "\f100";
}
.icon-uniF129:before {
  content: "\f129";
}
.icon-uniF11B:before {
  content: "\f11b";
}
.icon-uniF116:before {
  content: "\f116";
}
.icon-uniF140:before {
  content: "\f140";
}
.icon-uniF12A:before {
  content: "\f12a";
}
.icon-uniF1072:before {
  content: "\f108";
}
.icon-uniF109:before {
  content: "\f109";
}
.icon-uniF10B:before {
  content: "\f10b";
}
.icon-uniF143:before {
  content: "\f143";
}
.icon-uniF10E:before {
  content: "\f10e";
}
.icon-uniF136:before {
  content: "\f136";
}
.icon-uniF146:before {
  content: "\f146";
}
.icon-uniF119:before {
  content: "\f119";
}
.icon-uniF15D:before {
  content: "\f15d";
}
.icon-uniF108:before {
  content: "\f10a";
}
.icon-uniF10D2:before {
  content: "\f110";
}
.icon-uniF11C:before {
  content: "\f11c";
}
.icon-uniF1522:before {
  content: "\f153";
}
.icon-uniF117:before {
  content: "\f117";
}
.icon-uniF110:before {
  content: "\f112";
}
.icon-uniF11F:before {
  content: "\f11f";
}
.icon-uniF132:before {
  content: "\f132";
}
.icon-uniF121:before {
  content: "\f121";
}
.icon-uniF10A:before {
  content: "\f10c";
}
.icon-uniF14A:before {
  content: "\f14a";
}
.icon-uniF11A2:before {
  content: "\f11d";
}
.icon-uniF159:before {
  content: "\f159";
}
.icon-uniF14F:before {
  content: "\f14f";
}
.icon-uniF13D:before {
  content: "\f13f";
}
.icon-uniF13B:before {
  content: "\f13b";
}
.icon-uniF106:before {
  content: "\f106";
}
.icon-ion-android-arrow-dropdown:before {
  content: "\f35f";
}
.icon-ion-android-arrow-dropleft:before {
  content: "\f361";
}
.icon-ion-android-arrow-dropright:before {
  content: "\f363";
}
.icon-ion-android-arrow-dropup:before {
  content: "\f365";
}
.icon-ion-android-checkbox-outline-blank:before {
  content: "\f372";
}
.icon-ion-android-checkbox-outline:before {
  content: "\f373";
}
.icon-ion-android-checkbox:before {
  content: "\f374";
}
.icon-ion-android-checkmark-circle:before {
  content: "\f375";
}
.icon-ion-android-close:before {
  content: "\f2d7";
}
.icon-ion-android-drafts:before {
  content: "\f384";
}
.icon-ion-android-pin:before {
  content: "\f3a3";
}
.icon-ion-bug:before {
  content: "\f2be";
}
.icon-ion-gear-a:before {
  content: "\f13d";
}
.icon-ion-ios-checkmark-outline:before {
  content: "\f3fe";
}
.icon-ion-ios-checkmark:before {
  content: "\f3ff";
}
.icon-ion-ios-close-outline:before {
  content: "\f405";
}
.icon-ion-ios-contact-outline:before {
  content: "\f419";
}
.icon-ion-ios-contact:before {
  content: "\f41a";
}
.icon-ion-ios-email-outline:before {
  content: "\f422";
}
.icon-ion-ios-email:before {
  content: "\f423";
}
.icon-ion-ios-gear:before {
  content: "\f43d";
}
.icon-ion-ios-grid-view-outline:before {
  content: "\f440";
}
.icon-ion-ios-keypad-outline:before {
  content: "\f44f";
}
.icon-ion-ios-keypad:before {
  content: "\f450";
}
.icon-ion-ios-pulse-strong:before {
  content: "\f492";
}
.icon-ion-ios-pulse:before {
  content: "\f493";
}
.icon-ion-ios-search-strong:before {
  content: "\f4a4";
}
.icon-ion-person-add:before {
  content: "\f211";
}
.icon-ion-person-stalker:before {
  content: "\f212";
}
.icon-ion-person:before {
  content: "\f213";
}
.icon-ion-ribbon-a:before {
  content: "\f348";
}
.icon-ion-ribbon-b:before {
  content: "\f349";
}
.icon-ion-social-buffer:before {
  content: "\f229";
}
.icon-ion-social-facebook:before {
  content: "\f231";
}
.icon-heart2:before {
  content: "\e630";
}
.icon-cloud2:before {
  content: "\e631";
}
.icon-star2:before {
  content: "\e632";
}
.icon-trash2:before {
  content: "\e636";
}
.icon-user2:before {
  content: "\e637";
}
.icon-settings2:before {
  content: "\e63a";
}
.icon-camera2:before {
  content: "\e63b";
}
.icon-tag2:before {
  content: "\e63c";
}
.icon-location3:before {
  content: "\e642";
}
.icon-bubble3:before {
  content: "\e644";
}
.icon-like2:before {
  content: "\e64a";
}
.icon-paperplane2:before {
  content: "\e64e";
}
.icon-data2:before {
  content: "\e651";
}
.icon-calendar22:before {
  content: "\e65b";
}
.icon-book:before {
  content: "\e900";
}
.icon-users:before {
  content: "\e906";
}
.icon-account_circle:before {
  content: "\e863";
}
.icon-apps:before {
  content: "\e5c3";
}
.icon-arrow_drop_down:before {
  content: "\e5c5";
}
.icon-arrow_drop_up:before {
  content: "\e5c7";
}
.icon-dashboard:before {
  content: "\e872";
}
.icon-donut_large:before {
  content: "\e944";
}
.icon-filter:before {
  content: "\e3d3";
}
.icon-folder_shared:before {
  content: "\e2c9";
}
.icon-folder_special:before {
  content: "\e61f";
}
.icon-grain:before {
  content: "\e3ea";
}
.icon-loyalty:before {
  content: "\e8c2";
}
.icon-pie_chart:before {
  content: "\e6c4";
}
.icon-show_chart:before {
  content: "\e6e1";
}
.icon-stars:before {
  content: "\e8d0";
}
.icon-style:before {
  content: "\e41d";
}
.icon-timeline:before {
  content: "\e948";
}
.icon-unfold_more:before {
  content: "\e5d7";
}
.icon-vibration:before {
  content: "\e62d";
}
.icon-times:before {
  content: "\e90b";
}
.icon-tick:before {
  content: "\e90f";
}
.icon-plus:before {
  content: "\e915";
}
.icon-minus:before {
  content: "\e952";
}
.icon-equals:before {
  content: "\e953";
}
.icon-chevron-right:before {
  content: "\e954";
}
.icon-chevron-left:before {
  content: "\e955";
}
.icon-th-small:before {
  content: "\e956";
}
.icon-th-menu:before {
  content: "\e957";
}
.icon-home:before {
  content: "\e95d";
}
.icon-arrow-forward:before {
  content: "\e95e";
}
.icon-arrow-back:before {
  content: "\e961";
}
.icon-location:before {
  content: "\e969";
}
.icon-link:before {
  content: "\e99d";
}
.icon-image:before {
  content: "\e99e";
}
.icon-star:before {
  content: "\e99f";
}
.icon-flash:before {
  content: "\e9b5";
}
.icon-chart-bar:before {
  content: "\e9ca";
}
.icon-eye:before {
  content: "\e9cb";
}
.icon-cog:before {
  content: "\e9cf";
}
.icon-pin:before {
  content: "\e9d6";
}
.icon-input-checked:before {
  content: "\e9d8";
}
.icon-world:before {
  content: "\e9e1";
}
.icon-flag:before {
  content: "\e9e2";
}
.icon-media-record:before {
  content: "\e9e8";
}
.icon-th-large-outline:before {
  content: "\e9f7";
}
.icon-equals-outline:before {
  content: "\ea1b";
}
.icon-th-small-outline:before {
  content: "\ea1c";
}
.icon-message:before {
  content: "\ea1d";
}
.icon-flash-outline:before {
  content: "\ea1f";
}
.icon-lock-closed-outline:before {
  content: "\ea20";
}
.icon-chart-bar-outline:before {
  content: "\ea21";
}
.icon-chart-area-outline:before {
  content: "\ea22";
}
.icon-pin-outline:before {
  content: "\ea23";
}
.icon-business-card:before {
  content: "\ea24";
}
.icon-flow-merge:before {
  content: "\ea25";
}
.icon-lightbulb:before {
  content: "\ea26";
}
.icon-contacts:before {
  content: "\ea27";
}
.icon-weather-cloudy:before {
  content: "\ea28";
}
.icon-social-facebook-circular:before {
  content: "\ea29";
}
.icon-paper-clip:before {
  content: "\e004";
}
.icon-mail6:before {
  content: "\e005";
}
.icon-ribbon:before {
  content: "\e009";
}
.icon-image4:before {
  content: "\e010";
}
.icon-clipboard3:before {
  content: "\e014";
}
.icon-clock3:before {
  content: "\e015";
}
.icon-cog4:before {
  content: "\e023";
}
.icon-stack-2:before {
  content: "\e033";
}
.icon-paper:before {
  content: "\e034";
}
.icon-circle-plus:before {
  content: "\e040";
}
.icon-circle-minus2:before {
  content: "\e041";
}
.icon-circle-check:before {
  content: "\e042";
}
.icon-circle-cross:before {
  content: "\e043";
}
.icon-square-plus:before {
  content: "\e044";
}
.icon-square-minus:before {
  content: "\e045";
}
.icon-square-check:before {
  content: "\e046";
}
.icon-square-cross:before {
  content: "\e047";
}
.icon-folder3:before {
  content: "\e059";
}
.icon-cloud3:before {
  content: "\e066";
}
.icon-cloud-upload3:before {
  content: "\e067";
}
.icon-cloud-download3:before {
  content: "\e068";
}
.icon-head:before {
  content: "\e074";
}
.icon-speech-bubble:before {
  content: "\e077";
}
.icon-reload:before {
  content: "\e080";
}
.icon-share5:before {
  content: "\e081";
}
.icon-bar-graph2:before {
  content: "\e090";
}
.icon-bar-graph-2:before {
  content: "\e091";
}
.icon-pie-graph:before {
  content: "\e092";
}
.icon-arrow-left3:before {
  content: "\e094";
}
.icon-arrow-right3:before {
  content: "\e095";
}
.icon-arrow-up3:before {
  content: "\e096";
}
.icon-arrow-down3:before {
  content: "\e097";
}
.icon-grid2:before {
  content: "\e103";
}
.icon-loader:before {
  content: "\e105";
}
.icon-plus3:before {
  content: "\e114";
}
.icon-minus3:before {
  content: "\e115";
}
.icon-check3:before {
  content: "\e116";
}
.icon-cross3:before {
  content: "\e118";
}
.icon-inbox2:before {
  content: "\e122";
}
.icon-outbox:before {
  content: "\e123";
}
.icon-file:before {
  content: "\e124";
}
.icon-open:before {
  content: "\e128";
}
.icon-ellipsis:before {
  content: "\e129";
}
.icon-adjust2:before {
  content: "\e39e";
}
.icon-airplay:before {
  content: "\e055";
}
.icon-arrow_back:before {
  content: "\e5c4";
}
.icon-arrow_downward:before {
  content: "\e5db";
}
.icon-arrow_forward:before {
  content: "\e5c8";
}
.icon-arrow_upward:before {
  content: "\e5d8";
}
.icon-attach_file:before {
  content: "\e226";
}
.icon-turned_in:before {
  content: "\e8fc";
}
.icon-turned_in_not2:before {
  content: "\e909";
}
.icon-call_missed:before {
  content: "\e0b4";
}
.icon-call_missed_outgoing:before {
  content: "\e0e4";
}
.icon-call_split:before {
  content: "\e0b6";
}
.icon-chat_bubble_outline:before {
  content: "\e0cb";
}
.icon-mode_edit:before {
  content: "\e256";
}
.icon-crop_original:before {
  content: "\e3c4";
}
.icon-crop_square:before {
  content: "\e3c6";
}
.icon-date_range:before {
  content: "\e9de";
}
.icon-insert_invitation:before {
  content: "\e250";
}
.icon-event_available:before {
  content: "\e61c";
}
.icon-event_busy:before {
  content: "\e61d";
}
.icon-event_note:before {
  content: "\e61e";
}
.icon-fiber_manual_record:before {
  content: "\e062";
}
.icon-get_app2:before {
  content: "\e9df";
}
.icon-file_upload:before {
  content: "\e2c6";
}
.icon-highlight_off:before {
  content: "\e9e4";
}
.icon-label2:before {
  content: "\ea03";
}
.icon-label_outline2:before {
  content: "\ea04";
}
.icon-lightbulb_outline:before {
  content: "\e9e5";
}
.icon-list4:before {
  content: "\ea05";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-monetization_on:before {
  content: "\e263";
}
.icon-more_horiz:before {
  content: "\e5d3";
}
.icon-more_vert:before {
  content: "\e5d4";
}
.icon-photo_album:before {
  content: "\e411";
}
.icon-playlist_add_check:before {
  content: "\e065";
}
.icon-record_voice_over:before {
  content: "\e9e6";
}
.icon-slideshow:before {
  content: "\e41b";
}
.icon-today:before {
  content: "\ea06";
}
.icon-update:before {
  content: "\e932";
}
.icon-videocam:before {
  content: "\e04b";
}
.icon-web_asset:before {
  content: "\e06a";
}
.icon-error:before {
  content: "\e000";
}
.icon-error_outline:before {
  content: "\e001";
}
.icon-equalizer:before {
  content: "\e007";
}
.icon-loop:before {
  content: "\e00e";
}
.icon-mic3:before {
  content: "\e00f";
}
.icon-new_releases:before {
  content: "\e016";
}
.icon-play_arrow:before {
  content: "\e01b";
}
.icon-play_circle_filled:before {
  content: "\e01c";
}
.icon-play_circle_outline:before {
  content: "\e01d";
}
.icon-playlist_add:before {
  content: "\e01e";
}
.icon-recent_actors:before {
  content: "\e022";
}
.icon-replay:before {
  content: "\e025";
}
.icon-forum:before {
  content: "\e04d";
}
.icon-textsms:before {
  content: "\e061";
}
.icon-add:before {
  content: "\e069";
}
.icon-add_circle_outline:before {
  content: "\e06c";
}
.icon-content_paste:before {
  content: "\e073";
}
.icon-drafts:before {
  content: "\e075";
}
.icon-link3:before {
  content: "\e07b";
}
.icon-mail:before {
  content: "\e07c";
}
.icon-redo3:before {
  content: "\e07e";
}
.icon-report:before {
  content: "\e084";
}
.icon-access_time:before {
  content: "\e08e";
}
.icon-add_alarm:before {
  content: "\e08f";
}
.icon-data_usage:before {
  content: "\e09f";
}
.icon-now_widgets:before {
  content: "\e0ad";
}
.icon-insert_comment:before {
  content: "\e0e6";
}
.icon-insert_emoticon:before {
  content: "\e0e8";
}
.icon-insert_photo:before {
  content: "\e0eb";
}
.icon-mode_comment:before {
  content: "\e0ed";
}
.icon-attachment3:before {
  content: "\e0f7";
}
.icon-cloud_done:before {
  content: "\e0fa";
}
.icon-cloud_off:before {
  content: "\e0fc";
}
.icon-cloud_queue:before {
  content: "\e0fd";
}
.icon-cloud_upload:before {
  content: "\e0fe";
}
.icon-folder:before {
  content: "\e101";
}
.icon-folder_open:before {
  content: "\e102";
}
.icon-computer:before {
  content: "\e106";
}
.icon-desktop_mac:before {
  content: "\e107";
}
.icon-desktop_windows:before {
  content: "\e108";
}
.icon-keyboard_arrow_down:before {
  content: "\e10f";
}
.icon-keyboard_arrow_left:before {
  content: "\e110";
}
.icon-keyboard_arrow_right:before {
  content: "\e111";
}
.icon-keyboard_arrow_up:before {
  content: "\e112";
}
.icon-keyboard_backspace:before {
  content: "\e113";
}
.icon-keyboard_tab:before {
  content: "\e117";
}
.icon-laptop_chromebook:before {
  content: "\e11a";
}
.icon-security:before {
  content: "\e125";
}
.icon-device_hub:before {
  content: "\e130";
}
.icon-brightness_5:before {
  content: "\e13f";
}
.icon-camera_alt:before {
  content: "\e145";
}
.icon-filter_center_focus:before {
  content: "\e171";
}
.icon-filter_vintage:before {
  content: "\e177";
}
.icon-portrait:before {
  content: "\e1a7";
}
.icon-tune:before {
  content: "\e1ba";
}
.icon-wb_sunny:before {
  content: "\e1c0";
}
.icon-collections_bookmark:before {
  content: "\e1c1";
}
.icon-beenhere:before {
  content: "\e1c7";
}
.icon-layers2:before {
  content: "\e1d3";
}
.icon-local_mall:before {
  content: "\e1e4";
}
.icon-location_history:before {
  content: "\e1f2";
}
.icon-navigation:before {
  content: "\e1f5";
}
.icon-place:before {
  content: "\e1f7";
}
.icon-check2:before {
  content: "\e206";
}
.icon-close:before {
  content: "\e209";
}
.icon-fullscreen:before {
  content: "\e20c";
}
.icon-menu2:before {
  content: "\e20e";
}
.icon-more:before {
  content: "\e21f";
}
.icon-vpn_lock:before {
  content: "\e233";
}
.icon-group:before {
  content: "\e245";
}
.icon-group_add:before {
  content: "\e246";
}
.icon-mood:before {
  content: "\e248";
}
.icon-notifications:before {
  content: "\e24a";
}
.icon-notifications_none:before {
  content: "\e24b";
}
.icon-notifications_off:before {
  content: "\e24c";
}
.icon-notifications_active:before {
  content: "\e24d";
}
.icon-pages:before {
  content: "\e24f";
}
.icon-person:before {
  content: "\e253";
}
.icon-person_add:before {
  content: "\e254";
}
.icon-person_outline:before {
  content: "\e255";
}
.icon-public:before {
  content: "\e258";
}
.icon-school:before {
  content: "\e259";
}
.icon-share4:before {
  content: "\e25a";
}
.icon-whatshot:before {
  content: "\e25b";
}
.icon-star3:before {
  content: "\e260";
}
.icon-star_half:before {
  content: "\e261";
}
.icon-star_outline:before {
  content: "\e262";
}
.icon-add_shopping_cart:before {
  content: "\e269";
}
.icon-android2:before {
  content: "\e26e";
}
.icon-assessment:before {
  content: "\e271";
}
.icon-bookmark_outline:before {
  content: "\e27c";
}
.icon-cached:before {
  content: "\e27f";
}
.icon-change_history:before {
  content: "\e280";
}
.icon-code2:before {
  content: "\e284";
}
.icon-delete:before {
  content: "\e287";
}
.icon-done:before {
  content: "\e28a";
}
.icon-done_all:before {
  content: "\e28b";
}
.icon-explore:before {
  content: "\e28e";
}
.icon-extension:before {
  content: "\e28f";
}
.icon-favorite:before {
  content: "\e291";
}
.icon-favorite_outline:before {
  content: "\e292";
}
.icon-get_app:before {
  content: "\e298";
}
.icon-help2:before {
  content: "\e29b";
}
.icon-https:before {
  content: "\e2a1";
}
.icon-info:before {
  content: "\e2a2";
}
.icon-info_outline:before {
  content: "\e2a3";
}
.icon-input:before {
  content: "\e2a4";
}
.icon-label:before {
  content: "\e2a6";
}
.icon-label_outline:before {
  content: "\e2a7";
}
.icon-lock2:before {
  content: "\e2ab";
}
.icon-lock_open:before {
  content: "\e2ac";
}
.icon-lock_outline:before {
  content: "\e2ad";
}
.icon-note_add:before {
  content: "\e2b0";
}
.icon-open_in_browser:before {
  content: "\e2b1";
}
.icon-open_in_new:before {
  content: "\e2b2";
}
.icon-power_settings_new:before {
  content: "\e2c0";
}
.icon-query_builder:before {
  content: "\e2c2";
}
.icon-question_answer:before {
  content: "\e2c3";
}
.icon-search:before {
  content: "\e2ca";
}
.icon-settings:before {
  content: "\e2cb";
}
.icon-shopping_basket:before {
  content: "\e2de";
}
.icon-shopping_cart:before {
  content: "\e2df";
}
.icon-thumb_down:before {
  content: "\e2ed";
}
.icon-thumb_up:before {
  content: "\e2ee";
}
.icon-turned_in_not:before {
  content: "\e2f9";
}
.icon-visibility:before {
  content: "\e306";
}
.icon-card_giftcard:before {
  content: "\e308";
}
.icon-card_travel:before {
  content: "\e30a";
}
.icon-work:before {
  content: "\e30b";
}
.icon-help_outline:before {
  content: "\e30f";
}
.icon-reorder:before {
  content: "\e310";
}
.icon-chevron-down2:before {
  content: "\e6b1";
}
.icon-chevron-left22:before {
  content: "\e6b2";
}
.icon-chevron-right22:before {
  content: "\e6b3";
}
.icon-chevron-small-down:before {
  content: "\e6b4";
}
.icon-chevron-small-left:before {
  content: "\e6b5";
}
.icon-chevron-small-right:before {
  content: "\e6b6";
}
.icon-chevron-small-up:before {
  content: "\e6b7";
}
.icon-chevron-thin-down:before {
  content: "\e6b8";
}
.icon-chevron-thin-left:before {
  content: "\e6b9";
}
.icon-chevron-thin-right:before {
  content: "\e6ba";
}
.icon-chevron-thin-up:before {
  content: "\e6bb";
}
.icon-chevron-up2:before {
  content: "\e6bc";
}
.icon-chevron-with-circle-down:before {
  content: "\e6bd";
}
.icon-chevron-with-circle-left:before {
  content: "\e6be";
}
.icon-chevron-with-circle-right:before {
  content: "\e6bf";
}
.icon-chevron-with-circle-up:before {
  content: "\e6c0";
}
.icon-cloud4:before {
  content: "\e6c1";
}
.icon-controller-play:before {
  content: "\e6c6";
}
.icon-controller-record:before {
  content: "\e6c7";
}
.icon-controller-stop:before {
  content: "\e6c8";
}
.icon-dot-single:before {
  content: "\e6ca";
}
.icon-dots-three-horizontal:before {
  content: "\e6cb";
}
.icon-dots-three-vertical:before {
  content: "\e6cc";
}
.icon-dots-two-horizontal:before {
  content: "\e6cd";
}
.icon-dots-two-vertical:before {
  content: "\e6ce";
}
.icon-upload-to-cloud:before {
  content: "\e6e5";
}
.icon-adjust:before {
  content: "\e6e9";
}
.icon-back-in-time:before {
  content: "\e6f8";
}
.icon-bar-graph:before {
  content: "\e6fa";
}
.icon-beamed-note:before {
  content: "\e6fc";
}
.icon-block:before {
  content: "\e6ff";
}
.icon-book2:before {
  content: "\e700";
}
.icon-calendar4:before {
  content: "\e709";
}
.icon-camera4:before {
  content: "\e70a";
}
.icon-ccw:before {
  content: "\e70b";
}
.icon-check:before {
  content: "\e70d";
}
.icon-circle:before {
  content: "\e711";
}
.icon-code3:before {
  content: "\e716";
}
.icon-cog2:before {
  content: "\e717";
}
.icon-compass:before {
  content: "\e719";
}
.icon-credit-card:before {
  content: "\e71b";
}
.icon-credit:before {
  content: "\e71c";
}
.icon-cross2:before {
  content: "\e71d";
}
.icon-cw:before {
  content: "\e71f";
}
.icon-cycle:before {
  content: "\e720";
}
.icon-documents:before {
  content: "\e725";
}
.icon-drive:before {
  content: "\e727";
}
.icon-email:before {
  content: "\e72a";
}
.icon-export:before {
  content: "\e730";
}
.icon-flag3:before {
  content: "\e733";
}
.icon-flash2:before {
  content: "\e734";
}
.icon-forward2:before {
  content: "\e73b";
}
.icon-funnel:before {
  content: "\e73c";
}
.icon-game-controller:before {
  content: "\e73d";
}
.icon-globe:before {
  content: "\e73f";
}
.icon-graduation-cap:before {
  content: "\e740";
}
.icon-grid4:before {
  content: "\e741";
}
.icon-heart-outlined:before {
  content: "\e744";
}
.icon-heart4:before {
  content: "\e745";
}
.icon-help-with-circle:before {
  content: "\e746";
}
.icon-help:before {
  content: "\e747";
}
.icon-home2:before {
  content: "\e748";
}
.icon-image-inverted:before {
  content: "\e74a";
}
.icon-image5:before {
  content: "\e74b";
}
.icon-images:before {
  content: "\e74c";
}
.icon-infinity:before {
  content: "\e74e";
}
.icon-info-with-circle:before {
  content: "\e74f";
}
.icon-keyboard:before {
  content: "\e752";
}
.icon-laptop2:before {
  content: "\e756";
}
.icon-lifebuoy:before {
  content: "\e75a";
}
.icon-light-up:before {
  content: "\e75d";
}
.icon-line-graph:before {
  content: "\e75e";
}
.icon-link2:before {
  content: "\e75f";
}
.icon-list2:before {
  content: "\e760";
}
.icon-location-pin:before {
  content: "\e761";
}
.icon-location5:before {
  content: "\e762";
}
.icon-log-out:before {
  content: "\e765";
}
.icon-login:before {
  content: "\e766";
}
.icon-magnifying-glass:before {
  content: "\e769";
}
.icon-mail7:before {
  content: "\e76a";
}
.icon-map2:before {
  content: "\e76c";
}
.icon-medal:before {
  content: "\e76e";
}
.icon-menu3:before {
  content: "\e770";
}
.icon-message2:before {
  content: "\e771";
}
.icon-mic2:before {
  content: "\e772";
}
.icon-minus2:before {
  content: "\e773";
}
.icon-moon:before {
  content: "\e776";
}
.icon-new-message:before {
  content: "\e77a";
}
.icon-new:before {
  content: "\e77b";
}
.icon-news4:before {
  content: "\e77c";
}
.icon-note4:before {
  content: "\e77d";
}
.icon-notification:before {
  content: "\e77e";
}
.icon-old-phone:before {
  content: "\e780";
}
.icon-palette:before {
  content: "\e781";
}
.icon-paper-plane:before {
  content: "\e782";
}
.icon-phone4:before {
  content: "\e784";
}
.icon-pie-chart2:before {
  content: "\e785";
}
.icon-plus4:before {
  content: "\e787";
}
.icon-popup:before {
  content: "\e788";
}
.icon-print:before {
  content: "\e78c";
}
.icon-publish:before {
  content: "\e791";
}
.icon-quote:before {
  content: "\e792";
}
.icon-reply-all:before {
  content: "\e794";
}
.icon-reply:before {
  content: "\e795";
}
.icon-rss:before {
  content: "\e799";
}
.icon-ruler:before {
  content: "\e79a";
}
.icon-scissors:before {
  content: "\e79b";
}
.icon-share-alternitive:before {
  content: "\e79c";
}
.icon-share:before {
  content: "\e79d";
}
.icon-shield:before {
  content: "\e79f";
}
.icon-shopping-basket:before {
  content: "\e7a2";
}
.icon-shopping-cart:before {
  content: "\e7a3";
}
.icon-shuffle:before {
  content: "\e7a4";
}
.icon-signal:before {
  content: "\e7a5";
}
.icon-sound-mix:before {
  content: "\e7a6";
}
.icon-squared-cross:before {
  content: "\e7a9";
}
.icon-squared-minus:before {
  content: "\e7aa";
}
.icon-squared-plus:before {
  content: "\e7ab";
}
.icon-star-outlined:before {
  content: "\e7ac";
}
.icon-star4:before {
  content: "\e7ad";
}
.icon-suitcase:before {
  content: "\e7af";
}
.icon-swap:before {
  content: "\e7b0";
}
.icon-sweden:before {
  content: "\e7b1";
}
.icon-switch:before {
  content: "\e7b2";
}
.icon-tablet2:before {
  content: "\e7b3";
}
.icon-thumbs-down2:before {
  content: "\e7b9";
}
.icon-thumbs-up2:before {
  content: "\e7ba";
}
.icon-thunder-cloud:before {
  content: "\e7bb";
}
.icon-ticket:before {
  content: "\e7bc";
}
.icon-time-slot:before {
  content: "\e7bd";
}
.icon-typing:before {
  content: "\e7c3";
}
.icon-v-card:before {
  content: "\e7c8";
}
.icon-vinyl:before {
  content: "\e7ca";
}
.icon-wallet4:before {
  content: "\e7cc";
}
.icon-dropbox:before {
  content: "\e7d3";
}
.icon-evernote:before {
  content: "\e7d4";
}
.icon-google-drive:before {
  content: "\e7d7";
}
.icon-google-hangouts:before {
  content: "\e7d8";
}
.icon-icloud:before {
  content: "\e7da";
}
.icon-onedrive:before {
  content: "\e7dc";
}
.icon-paypal:before {
  content: "\e7dd";
}
.icon-picasa:before {
  content: "\e7de";
}
.icon-skype-with-circle:before {
  content: "\e7e4";
}
.icon-skype:before {
  content: "\e7e5";
}
.icon-soundcloud:before {
  content: "\e7e8";
}
.icon-spotify-with-circle:before {
  content: "\e7e9";
}
.icon-swarm:before {
  content: "\e7eb";
}
.icon-xing-with-circle:before {
  content: "\e7f1";
}
.icon-xing:before {
  content: "\e7f2";
}
.icon-yelp:before {
  content: "\e7f3";
}
.icon-dribbble:before {
  content: "\e7f5";
}
.icon-facebook-with-circle:before {
  content: "\e7f6";
}
.icon-facebook:before {
  content: "\e7f7";
}
.icon-flickr-with-circle:before {
  content: "\e7f8";
}
.icon-flickr:before {
  content: "\e7f9";
}
.icon-google:before {
  content: "\e7fd";
}
.icon-instagram:before {
  content: "\e7ff";
}
.icon-pinterest:before {
  content: "\e805";
}
.icon-tumblr:before {
  content: "\e80c";
}
.icon-twitter:before {
  content: "\e816";
}
.icon-youtube:before {
  content: "\e817";
}
.icon-alarm3:before {
  content: "\e858";
}
.icon-arrow-down:before {
  content: "\e878";
}
.icon-arrow-down-circle:before {
  content: "\e884";
}
.icon-arrow-left:before {
  content: "\e879";
}
.icon-arrow-left-circle:before {
  content: "\e885";
}
.icon-arrow-right:before {
  content: "\e87a";
}
.icon-arrow-right-circle:before {
  content: "\e886";
}
.icon-arrow-up:before {
  content: "\e877";
}
.icon-arrow-up-circle:before {
  content: "\e883";
}
.icon-book4:before {
  content: "\e828";
}
.icon-bookmark:before {
  content: "\e829";
}
.icon-briefcase:before {
  content: "\e84c";
}
.icon-bubble:before {
  content: "\e83f";
}
.icon-bug:before {
  content: "\e869";
}
.icon-calendar-full:before {
  content: "\e836";
}
.icon-camera:before {
  content: "\e826";
}
.icon-camera-video:before {
  content: "\e825";
}
.icon-cart:before {
  content: "\e82e";
}
.icon-chart-bars:before {
  content: "\e843";
}
.icon-checkmark-circle:before {
  content: "\e87f";
}
.icon-chevron-down3:before {
  content: "\e874";
}
.icon-chevron-down-circle:before {
  content: "\e888";
}
.icon-chevron-left3:before {
  content: "\e875";
}
.icon-chevron-left-circle:before {
  content: "\e889";
}
.icon-chevron-right3:before {
  content: "\e876";
}
.icon-chevron-right-circle:before {
  content: "\e88a";
}
.icon-chevron-up3:before {
  content: "\e873";
}
.icon-chevron-up-circle:before {
  content: "\e887";
}
.icon-circle-minus:before {
  content: "\e882";
}
.icon-cloud:before {
  content: "\e809";
}
.icon-cloud-check:before {
  content: "\e80d";
}
.icon-cloud-download:before {
  content: "\e80b";
}
.icon-cloud-upload:before {
  content: "\e80a";
}
.icon-code:before {
  content: "\e86a";
}
.icon-crop:before {
  content: "\e88b";
}
.icon-cross:before {
  content: "\e870";
}
.icon-cross-circle:before {
  content: "\e880";
}
.icon-database:before {
  content: "\e80e";
}
.icon-diamond:before {
  content: "\e845";
}
.icon-dice:before {
  content: "\e812";
}
.icon-download:before {
  content: "\e865";
}
.icon-drop:before {
  content: "\e804";
}
.icon-enter:before {
  content: "\e81f";
}
.icon-enter-down:before {
  content: "\e867";
}
.icon-envelope:before {
  content: "\e818";
}
.icon-exit:before {
  content: "\e820";
}
.icon-exit-up:before {
  content: "\e868";
}
.icon-file-add:before {
  content: "\e81e";
}
.icon-file-empty:before {
  content: "\e81d";
}
.icon-film-play:before {
  content: "\e824";
}
.icon-gift:before {
  content: "\e844";
}
.icon-heart:before {
  content: "\e813";
}
.icon-heart-pulse:before {
  content: "\e840";
}
.icon-inbox:before {
  content: "\e81a";
}
.icon-laptop:before {
  content: "\e83c";
}
.icon-laptop-phone:before {
  content: "\e83d";
}
.icon-layers:before {
  content: "\e88e";
}
.icon-license:before {
  content: "\e822";
}
.icon-line-spacing:before {
  content: "\e89c";
}
.icon-link5:before {
  content: "\e86b";
}
.icon-lock3:before {
  content: "\e80f";
}
.icon-magic-wand2:before {
  content: "\e803";
}
.icon-magnifier:before {
  content: "\e86f";
}
.icon-map:before {
  content: "\e834";
}
.icon-menu:before {
  content: "\e871";
}
.icon-menu-circle:before {
  content: "\e87e";
}
.icon-mic:before {
  content: "\e85e";
}
.icon-move:before {
  content: "\e87b";
}
.icon-music-note:before {
  content: "\e823";
}
.icon-paw:before {
  content: "\e84a";
}
.icon-pencil:before {
  content: "\e802";
}
.icon-phone-handset:before {
  content: "\e9d1";
}
.icon-pie-chart:before {
  content: "\e842";
}
.icon-plus-circle:before {
  content: "\e881";
}
.icon-pointer-down:before {
  content: "\e9bc";
}
.icon-pointer-left:before {
  content: "\e9be";
}
.icon-pointer-right:before {
  content: "\e9bd";
}
.icon-pointer-up:before {
  content: "\e8a6";
}
.icon-power-switch:before {
  content: "\e83e";
}
.icon-printer:before {
  content: "\e81c";
}
.icon-question-circle:before {
  content: "\e87d";
}
.icon-redo:before {
  content: "\e861";
}
.icon-screen:before {
  content: "\e839";
}
.icon-select:before {
  content: "\e852";
}
.icon-smartphone:before {
  content: "\e83a";
}
.icon-smile:before {
  content: "\e854";
}
.icon-sort-alpha-asc:before {
  content: "\e8a3";
}
.icon-sort-amount-asc:before {
  content: "\e8a4";
}
.icon-star5:before {
  content: "\e814";
}
.icon-star-empty2:before {
  content: "\e9bf";
}
.icon-star-half:before {
  content: "\e815";
}
.icon-sync:before {
  content: "\e862";
}
.icon-tablet:before {
  content: "\e83b";
}
.icon-tag:before {
  content: "\e82f";
}
.icon-text-align-center:before {
  content: "\e899";
}
.icon-text-align-justify:before {
  content: "\e89b";
}
.icon-text-align-left:before {
  content: "\e898";
}
.icon-text-align-right:before {
  content: "\e89a";
}
.icon-text-format:before {
  content: "\e890";
}
.icon-thumbs-down:before {
  content: "\e86e";
}
.icon-thumbs-up:before {
  content: "\e86d";
}
.icon-trash:before {
  content: "\e811";
}
.icon-undo:before {
  content: "\e860";
}
.icon-unlink:before {
  content: "\e86c";
}
.icon-upload:before {
  content: "\e866";
}
.icon-user6:before {
  content: "\e82a";
}
.icon-users3:before {
  content: "\e82b";
}
.icon-volume:before {
  content: "\e85d";
}
.icon-volume-high:before {
  content: "\e85a";
}
.icon-volume-low:before {
  content: "\e85c";
}
.icon-volume-medium:before {
  content: "\e85b";
}
.icon-warning:before {
  content: "\e87c";
}
.icon-plus42:before {
  content: "\e608";
}
.icon-user:before {
  content: "\ea2a";
}
.icon-checkmark:before {
  content: "\ea2b";
}
.icon-alarm:before {
  content: "\ea2c";
}
.icon-grid:before {
  content: "\ea2d";
}
.icon-forward:before {
  content: "\ea2e";
}
.icon-addressbook:before {
  content: "\ea2f";
}
.icon-calendar:before {
  content: "\f068";
}
.icon-chevron-down:before {
  content: "\f0a3";
}
.icon-chevron-left2:before {
  content: "\f0a4";
}
.icon-chevron-right2:before {
  content: "\f078";
}
.icon-chevron-up:before {
  content: "\f0a2";
}
.icon-comment:before {
  content: "\f02b";
}
.icon-diff-added:before {
  content: "\f06b";
}
.icon-file-directory:before {
  content: "\f016";
}
.icon-list-unordered:before {
  content: "\f061";
}
.icon-lock:before {
  content: "\f06a";
}
.icon-mail2:before {
  content: "\f03b";
}
.icon-mail-read:before {
  content: "\f03c";
}
.icon-plus2:before {
  content: "\f05d";
}
.icon-primitive-dot:before {
  content: "\f052";
}
.icon-repo:before {
  content: "\f001";
}
.icon-ruby:before {
  content: "\f047";
}
.icon-search2:before {
  content: "\f02e";
}
.icon-triangle-down:before {
  content: "\f05b";
}
.icon-triangle-left:before {
  content: "\f044";
}
.icon-triangle-right:before {
  content: "\f05a";
}
.icon-triangle-up:before {
  content: "\f0aa";
}
.icon-out:before {
  content: "\e90a";
}
.icon-in:before {
  content: "\e90c";
}
.icon-in-alt:before {
  content: "\e933";
}
.icon-home5:before {
  content: "\e934";
}
.icon-add2:before {
  content: "\e939";
}
.icon-subtract:before {
  content: "\e93b";
}
.icon-exclamation:before {
  content: "\e93c";
}
.icon-question2:before {
  content: "\e93d";
}
.icon-close2:before {
  content: "\e9aa";
}
.icon-cmd:before {
  content: "\e9b2";
}
.icon-forbid:before {
  content: "\e9b3";
}
.icon-envelope3:before {
  content: "\e9c8";
}
.icon-compose:before {
  content: "\e9c9";
}
.icon-trash3:before {
  content: "\ea07";
}
.icon-trash-alt:before {
  content: "\ea08";
}
.icon-grid3:before {
  content: "\ea09";
}
.icon-grid-alt:before {
  content: "\ea0a";
}
.icon-menu4:before {
  content: "\ea0b";
}
.icon-list5:before {
  content: "\ea0c";
}
.icon-gallery:before {
  content: "\ea0d";
}
.icon-clock4:before {
  content: "\ea0e";
}
.icon-attachment2:before {
  content: "\ea0f";
}
.icon-user3:before {
  content: "\ea10";
}
.icon-chat:before {
  content: "\ea11";
}
.icon-comments:before {
  content: "\ea12";
}
.icon-screen2:before {
  content: "\ea13";
}
.icon-googleplus:before {
  content: "\ea14";
}
.icon-pin2:before {
  content: "\ea15";
}
.icon-cloud6:before {
  content: "\ea16";
}
.icon-circle-full:before {
  content: "\ea17";
}
.icon-comment-alt2-stroke:before {
  content: "\e9f1";
}
.icon-comment-alt2-fill:before {
  content: "\e9f2";
}
.icon-mail5:before {
  content: "\e9f3";
}
.icon-pin3:before {
  content: "\ea18";
}
.icon-link4:before {
  content: "\ea19";
}
.icon-loop-alt1:before {
  content: "\ea1a";
}
.icon-left-quote:before {
  content: "\e9f4";
}
.icon-right-quote:before {
  content: "\e9f5";
}
.icon-pen:before {
  content: "\e9f9";
}
.icon-pen-alt-stroke:before {
  content: "\e9fa";
}
.icon-pen-alt-fill:before {
  content: "\e9fb";
}
.icon-pen-alt2:before {
  content: "\e9fc";
}
.icon-chart2:before {
  content: "\e9fd";
}
.icon-chart-alt:before {
  content: "\e9fe";
}
.icon-bars2:before {
  content: "\e9ff";
}
.icon-bars-alt:before {
  content: "\ea00";
}
.icon-unlock-stroke:before {
  content: "\ea01";
}
.icon-unlock-fill:before {
  content: "\ea02";
}
.icon-warning3:before {
  content: "\e60c";
}
.icon-envelope2:before {
  content: "\e60d";
}
.icon-picture2:before {
  content: "\e60e";
}
.icon-earth2:before {
  content: "\e60f";
}
.icon-bookmark2:before {
  content: "\e610";
}
.icon-calendar2:before {
  content: "\e611";
}
.icon-location2:before {
  content: "\e612";
}
.icon-gift2:before {
  content: "\e613";
}
.icon-flag2:before {
  content: "\e614";
}
.icon-bookmark3:before {
  content: "\e615";
}
.icon-atom:before {
  content: "\e616";
}
.icon-flame:before {
  content: "\f0d2";
}
.icon-gear:before {
  content: "\f02f";
}
.icon-gift3:before {
  content: "\f042";
}
.icon-stop3:before {
  content: "\f08f";
}
.icon-checkmark3:before {
  content: "\e617";
}
.icon-cog3:before {
  content: "\e600";
}
.icon-lightbulb2:before {
  content: "\e601";
}
.icon-hash:before {
  content: "\e602";
}
.icon-left-quote-alt:before {
  content: "\e609";
}
.icon-right-quote-alt:before {
  content: "\e60a";
}
.icon-book3:before {
  content: "\e603";
}
.icon-book-alt:before {
  content: "\e618";
}
.icon-image3:before {
  content: "\e619";
}
.icon-chart:before {
  content: "\e604";
}
.icon-bars:before {
  content: "\e61a";
}
.icon-clock2:before {
  content: "\e605";
}
.icon-star32:before {
  content: "\e606";
}
.icon-map-pin-fill:before {
  content: "\e607";
}
.icon-download5:before {
  content: "\e61b";
}
.icon-paperclip:before {
  content: "\e60b";
}
.icon-home4:before {
  content: "\e819";
}
.icon-home3:before {
  content: "\e821";
}
.icon-newspaper:before {
  content: "\e82c";
}
.icon-pencil3:before {
  content: "\e82d";
}
.icon-pencil22:before {
  content: "\e830";
}
.icon-eyedropper:before {
  content: "\e831";
}
.icon-image2:before {
  content: "\e832";
}
.icon-images2:before {
  content: "\e833";
}
.icon-camera5:before {
  content: "\e835";
}
.icon-music5:before {
  content: "\e838";
}
.icon-play:before {
  content: "\e841";
}
.icon-film:before {
  content: "\e846";
}
.icon-video-camera:before {
  content: "\e847";
}
.icon-spades:before {
  content: "\e9db";
}
.icon-clubs:before {
  content: "\e9dc";
}
.icon-diamonds:before {
  content: "\e848";
}
.icon-bullhorn:before {
  content: "\e849";
}
.icon-connection:before {
  content: "\e84b";
}
.icon-feed:before {
  content: "\e84d";
}
.icon-mic22:before {
  content: "\e84e";
}
.icon-book22:before {
  content: "\e84f";
}
.icon-books:before {
  content: "\e850";
}
.icon-file-text:before {
  content: "\e853";
}
.icon-profile:before {
  content: "\e855";
}
.icon-file-empty2:before {
  content: "\e856";
}
.icon-files-empty:before {
  content: "\e857";
}
.icon-file-text2:before {
  content: "\e859";
}
.icon-file-picture:before {
  content: "\e85f";
}
.icon-file-music:before {
  content: "\e88f";
}
.icon-file-play:before {
  content: "\e891";
}
.icon-file-video:before {
  content: "\e893";
}
.icon-file-zip:before {
  content: "\e894";
}
.icon-paste:before {
  content: "\e895";
}
.icon-stack4:before {
  content: "\e896";
}
.icon-folder2:before {
  content: "\e897";
}
.icon-folder-open:before {
  content: "\e89d";
}
.icon-folder-plus:before {
  content: "\e89e";
}
.icon-folder-minus:before {
  content: "\e89f";
}
.icon-folder-download:before {
  content: "\e8a0";
}
.icon-folder-upload:before {
  content: "\e8a1";
}
.icon-price-tag2:before {
  content: "\e8a2";
}
.icon-price-tags:before {
  content: "\e8a5";
}
.icon-barcode:before {
  content: "\e8a7";
}
.icon-qrcode:before {
  content: "\e8a8";
}
.icon-ticket2:before {
  content: "\e8a9";
}
.icon-cart2:before {
  content: "\e8aa";
}
.icon-coin-dollar:before {
  content: "\e8ab";
}
.icon-coin-euro:before {
  content: "\e8ac";
}
.icon-coin-pound:before {
  content: "\e8ad";
}
.icon-coin-yen:before {
  content: "\e8ae";
}
.icon-credit-card2:before {
  content: "\e8af";
}
.icon-calculator2:before {
  content: "\e8b0";
}
.icon-lifebuoy2:before {
  content: "\e8b1";
}
.icon-phone5:before {
  content: "\e8b2";
}
.icon-phone-hang-up:before {
  content: "\e8b3";
}
.icon-address-book:before {
  content: "\e8b4";
}
.icon-envelop:before {
  content: "\e8b5";
}
.icon-location6:before {
  content: "\e8b7";
}
.icon-location22:before {
  content: "\e8b8";
}
.icon-compass3:before {
  content: "\e8b9";
}
.icon-compass2:before {
  content: "\e8ba";
}
.icon-map3:before {
  content: "\e8bb";
}
.icon-map22:before {
  content: "\e8bc";
}
.icon-history2:before {
  content: "\e8bd";
}
.icon-clock6:before {
  content: "\e8be";
}
.icon-clock22:before {
  content: "\e8bf";
}
.icon-alarm2:before {
  content: "\e8c0";
}
.icon-bell2:before {
  content: "\e8c1";
}
.icon-calendar5:before {
  content: "\e8c3";
}
.icon-printer2:before {
  content: "\e8c4";
}
.icon-keyboard2:before {
  content: "\e8c5";
}
.icon-display4:before {
  content: "\e8c6";
}
.icon-laptop22:before {
  content: "\e8c7";
}
.icon-mobile3:before {
  content: "\e8c8";
}
.icon-mobile2:before {
  content: "\e8c9";
}
.icon-tablet22:before {
  content: "\e8ca";
}
.icon-tv5:before {
  content: "\e8cb";
}
.icon-drawer:before {
  content: "\e8cc";
}
.icon-drawer2:before {
  content: "\e8cd";
}
.icon-box-add:before {
  content: "\e8ce";
}
.icon-box-remove:before {
  content: "\e8cf";
}
.icon-drive2:before {
  content: "\e8d3";
}
.icon-database2:before {
  content: "\e8d4";
}
.icon-undo2:before {
  content: "\e8d5";
}
.icon-redo2:before {
  content: "\e8d6";
}
.icon-undo22:before {
  content: "\e8d7";
}
.icon-redo22:before {
  content: "\e8d8";
}
.icon-forward4:before {
  content: "\e8d9";
}
.icon-reply2:before {
  content: "\e8da";
}
.icon-bubble5:before {
  content: "\e8db";
}
.icon-bubbles:before {
  content: "\e8dc";
}
.icon-bubbles2:before {
  content: "\e8dd";
}
.icon-bubble2:before {
  content: "\e8de";
}
.icon-bubbles3:before {
  content: "\e8df";
}
.icon-bubbles4:before {
  content: "\e8e0";
}
.icon-user5:before {
  content: "\e8e1";
}
.icon-user-plus:before {
  content: "\e8e3";
}
.icon-user-minus:before {
  content: "\e8e4";
}
.icon-user-check:before {
  content: "\e8e5";
}
.icon-quotes-left:before {
  content: "\e8e7";
}
.icon-quotes-right:before {
  content: "\e8e8";
}
.icon-hour-glass2:before {
  content: "\e8e9";
}
.icon-spinner:before {
  content: "\e8ea";
}
.icon-spinner2:before {
  content: "\e8eb";
}
.icon-spinner3:before {
  content: "\e8ec";
}
.icon-spinner4:before {
  content: "\e8ed";
}
.icon-spinner6:before {
  content: "\e8ef";
}
.icon-spinner7:before {
  content: "\e8f0";
}
.icon-spinner8:before {
  content: "\e8f1";
}
.icon-spinner9:before {
  content: "\e8f2";
}
.icon-spinner11:before {
  content: "\e8f3";
}
.icon-enlarge:before {
  content: "\e8f6";
}
.icon-lock5:before {
  content: "\e8fa";
}
.icon-unlocked:before {
  content: "\e8fb";
}
.icon-cog22:before {
  content: "\e8fe";
}
.icon-aid-kit:before {
  content: "\e901";
}
.icon-bug2:before {
  content: "\e902";
}
.icon-pie-chart22:before {
  content: "\e903";
}
.icon-stats-dots:before {
  content: "\e904";
}
.icon-stats-bars:before {
  content: "\e905";
}
.icon-trophy2:before {
  content: "\e907";
}
.icon-gift4:before {
  content: "\e908";
}
.icon-fire4:before {
  content: "\e90d";
}
.icon-bin:before {
  content: "\e90e";
}
.icon-briefcase2:before {
  content: "\e910";
}
.icon-truck4:before {
  content: "\e911";
}
.icon-switch2:before {
  content: "\e912";
}
.icon-clipboard2:before {
  content: "\e913";
}
.icon-tree2:before {
  content: "\e917";
}
.icon-cloud5:before {
  content: "\e919";
}
.icon-cloud-check2:before {
  content: "\e91c";
}
.icon-download22:before {
  content: "\e91d";
}
.icon-upload22:before {
  content: "\e91e";
}
.icon-download3:before {
  content: "\e91f";
}
.icon-upload3:before {
  content: "\e920";
}
.icon-sphere:before {
  content: "\e921";
}
.icon-earth:before {
  content: "\e922";
}
.icon-link22:before {
  content: "\e923";
}
.icon-attachment22:before {
  content: "\e924";
}
.icon-bookmark22:before {
  content: "\e929";
}
.icon-bookmarks2:before {
  content: "\e92a";
}
.icon-sun:before {
  content: "\e92b";
}
.icon-contrast:before {
  content: "\e92c";
}
.icon-star-empty:before {
  content: "\e92d";
}
.icon-star-half2:before {
  content: "\e92e";
}
.icon-star-full:before {
  content: "\e92f";
}
.icon-heart5:before {
  content: "\e930";
}
.icon-heart-broken:before {
  content: "\e931";
}
.icon-happy:before {
  content: "\e935";
}
.icon-happy2:before {
  content: "\e936";
}
.icon-smile2:before {
  content: "\e937";
}
.icon-smile22:before {
  content: "\e938";
}
.icon-sad:before {
  content: "\e93a";
}
.icon-shocked:before {
  content: "\e93e";
}
.icon-neutral:before {
  content: "\e93f";
}
.icon-point-up:before {
  content: "\e940";
}
.icon-point-right:before {
  content: "\e941";
}
.icon-point-down:before {
  content: "\e942";
}
.icon-point-left:before {
  content: "\e943";
}
.icon-notification2:before {
  content: "\e945";
}
.icon-question:before {
  content: "\e946";
}
.icon-plus22:before {
  content: "\e947";
}
.icon-info2:before {
  content: "\e949";
}
.icon-cancel-circle:before {
  content: "\e94a";
}
.icon-blocked:before {
  content: "\e94b";
}
.icon-checkmark2:before {
  content: "\e94d";
}
.icon-checkmark22:before {
  content: "\e94e";
}
.icon-enter2:before {
  content: "\e94f";
}
.icon-exit2:before {
  content: "\e950";
}
.icon-play2:before {
  content: "\e951";
}
.icon-play3:before {
  content: "\e958";
}
.icon-pause2:before {
  content: "\e959";
}
.icon-stop2:before {
  content: "\e95a";
}
.icon-backward2:before {
  content: "\e95b";
}
.icon-forward3:before {
  content: "\e95c";
}
.icon-previous2:before {
  content: "\e95f";
}
.icon-next2:before {
  content: "\e960";
}
.icon-volume-high2:before {
  content: "\e962";
}
.icon-volume-medium2:before {
  content: "\e963";
}
.icon-volume-low2:before {
  content: "\e964";
}
.icon-volume-mute:before {
  content: "\e965";
}
.icon-volume-mute2:before {
  content: "\e966";
}
.icon-volume-increase:before {
  content: "\e967";
}
.icon-volume-decrease:before {
  content: "\e968";
}
.icon-loop2:before {
  content: "\e96a";
}
.icon-infinite:before {
  content: "\e96b";
}
.icon-shuffle2:before {
  content: "\e96c";
}
.icon-arrow-up-left2:before {
  content: "\e96d";
}
.icon-arrow-up2:before {
  content: "\e96e";
}
.icon-arrow-up-right2:before {
  content: "\e96f";
}
.icon-arrow-right2:before {
  content: "\e970";
}
.icon-arrow-down-right2:before {
  content: "\e971";
}
.icon-arrow-down2:before {
  content: "\e972";
}
.icon-arrow-down-left2:before {
  content: "\e973";
}
.icon-arrow-left2:before {
  content: "\e974";
}
.icon-circle-up:before {
  content: "\e975";
}
.icon-circle-right:before {
  content: "\e976";
}
.icon-circle-down:before {
  content: "\e977";
}
.icon-circle-left:before {
  content: "\e978";
}
.icon-tab:before {
  content: "\e979";
}
.icon-move-up:before {
  content: "\e97a";
}
.icon-move-down:before {
  content: "\e97b";
}
.icon-sort-alpha-asc2:before {
  content: "\e97c";
}
.icon-sort-alpha-desc:before {
  content: "\e97d";
}
.icon-sort-numeric-asc:before {
  content: "\e97e";
}
.icon-sort-numberic-desc:before {
  content: "\e97f";
}
.icon-sort-amount-asc2:before {
  content: "\e980";
}
.icon-sort-amount-desc:before {
  content: "\e981";
}
.icon-command:before {
  content: "\e982";
}
.icon-shift:before {
  content: "\e983";
}
.icon-ctrl:before {
  content: "\e984";
}
.icon-checkbox-checked:before {
  content: "\e986";
}
.icon-checkbox-unchecked:before {
  content: "\e987";
}
.icon-radio-checked:before {
  content: "\e988";
}
.icon-radio-checked2:before {
  content: "\e989";
}
.icon-radio-unchecked:before {
  content: "\e98a";
}
.icon-crop2:before {
  content: "\e98b";
}
.icon-ungroup:before {
  content: "\e98c";
}
.icon-scissors2:before {
  content: "\e98d";
}
.icon-filter2:before {
  content: "\e9bb";
}
.icon-table:before {
  content: "\e98e";
}
.icon-table2:before {
  content: "\e98f";
}
.icon-share3:before {
  content: "\e997";
}
.icon-new-tab:before {
  content: "\e998";
}
.icon-embed:before {
  content: "\e999";
}
.icon-embed2:before {
  content: "\e99a";
}
.icon-terminal:before {
  content: "\e99b";
}
.icon-share2:before {
  content: "\e99c";
}
.icon-google2:before {
  content: "\e9a0";
}
.icon-google-plus:before {
  content: "\e9a1";
}
.icon-google-plus2:before {
  content: "\e9a2";
}
.icon-google-plus3:before {
  content: "\e9a3";
}
.icon-google-drive2:before {
  content: "\e9a4";
}
.icon-facebook4:before {
  content: "\e9a5";
}
.icon-facebook2:before {
  content: "\e9a6";
}
.icon-facebook3:before {
  content: "\e9a7";
}
.icon-twitter4:before {
  content: "\e9a8";
}
.icon-twitter2:before {
  content: "\e9a9";
}
.icon-feed2:before {
  content: "\e9ab";
}
.icon-feed3:before {
  content: "\e9ac";
}
.icon-feed4:before {
  content: "\e9ad";
}
.icon-youtube5:before {
  content: "\e9ae";
}
.icon-youtube2:before {
  content: "\e9af";
}
.icon-youtube3:before {
  content: "\e9b0";
}
.icon-vimeo2:before {
  content: "\e9b4";
}
.icon-flickr5:before {
  content: "\e9b6";
}
.icon-flickr2:before {
  content: "\e9b7";
}
.icon-flickr3:before {
  content: "\e9b8";
}
.icon-picassa:before {
  content: "\e9b9";
}
.icon-dribbble4:before {
  content: "\e9ba";
}
.icon-dropbox2:before {
  content: "\e9c0";
}
.icon-onedrive2:before {
  content: "\e9c1";
}
.icon-github6:before {
  content: "\e9c2";
}
.icon-github2:before {
  content: "\e9c3";
}
.icon-github4:before {
  content: "\e9c5";
}
.icon-github5:before {
  content: "\e9c6";
}
.icon-wordpress:before {
  content: "\e9c7";
}
.icon-tumblr3:before {
  content: "\e9cc";
}
.icon-tumblr2:before {
  content: "\e9cd";
}
.icon-yahoo:before {
  content: "\e9ce";
}
.icon-apple:before {
  content: "\e9d0";
}
.icon-android:before {
  content: "\e9d2";
}
.icon-windows:before {
  content: "\e9d3";
}
.icon-windows8:before {
  content: "\e9d4";
}
.icon-soundcloud3:before {
  content: "\e9d5";
}
.icon-skype2:before {
  content: "\e9d7";
}
.icon-linkedin3:before {
  content: "\e9d9";
}
.icon-linkedin2:before {
  content: "\e9da";
}
.icon-delicious:before {
  content: "\e9dd";
}
.icon-stackoverflow:before {
  content: "\e9e0";
}
.icon-xing3:before {
  content: "\e9e3";
}
.icon-paypal4:before {
  content: "\e9e7";
}
.icon-paypal3:before {
  content: "\e9e9";
}
.icon-yelp2:before {
  content: "\e9ea";
}
.icon-file-pdf:before {
  content: "\e9eb";
}
.icon-file-openoffice:before {
  content: "\e9ec";
}
.icon-file-word:before {
  content: "\e9ed";
}
.icon-file-excel:before {
  content: "\e9ee";
}
.icon-libreoffice:before {
  content: "\e9ef";
}
.icon-html5:before {
  content: "\e9f0";
}
.icon-chrome:before {
  content: "\e9f6";
}
.icon-IE:before {
  content: "\e9f8";
}

