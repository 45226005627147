/* You can add global styles to this file, and also import other style files */

/* Toastr */
@import "~ngx-toastr/toastr.css";


/* === Layout === */
@import "assets/scss/style";

/* ---------------------
	*** Icons Scss ***
-----------------------*/

@import "assets/vendor/font-awesome/css/font-awesome.min.css";
@import "assets/vendor/line-awesome/css/line-awesome.css";
@import "assets/vendor/ap8/css/style.css";
@import "assets/vendor/vcicon/style.css";

@import "assets/css/bootstrap.min.css";
@import "assets/css/atlantis.min.css";
@import "assets/css/demo.css";
@import "assets/css/fonts.min.css";

// @import "assets/vendor/pace/pace.css";
