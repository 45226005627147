@font-face {
  font-family: 'vcicon';
  src:  url('fonts/vcicon.eot?y1j9fg');
  src:  url('fonts/vcicon.eot?y1j9fg#iefix') format('embedded-opentype'),
    url('fonts/vcicon.ttf?y1j9fg') format('truetype'),
    url('fonts/vcicon.woff?y1j9fg') format('woff'),
    url('fonts/vcicon.svg?y1j9fg#vcicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="vc-"], [class*=" vc-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vcicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;    
  cursor: pointer;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vc-add-new-file:before {
  content: "\e900";
}
.vc-approve-file:before {
  content: "\e901";
}
.vc-calendar:before {
  content: "\e902";
}
.vc-document:before {
  content: "\e903";
}
.vc-file-map:before {
  content: "\e904";
}
.vc-history:before {
  content: "\e905";
}
.vc-notify:before {
  content: "\e906";
}
.vc-profile:before {
  content: "\e907";
}
.vc-setting:before {
  content: "\e908";
}
