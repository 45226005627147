/* Fonts */



/* Nunito */
@font-face {
    font-family: 'Inter';
    src:url('../../fonts/Inter/Inter-Bold.eot?#iefix') format('embedded-opentype'), 
        url('../../fonts/Inter/Inter-Bold.woff') format('woff'), 
        url('../../fonts/Inter/Inter-Bold.ttf') format('truetype'), 
        url('../../fonts/Inter/Inter-Bold.svg#SFUFutura') format('svg');
    font-weight: bold;
}
@font-face {
    font-family: 'Inter';
    src:url('../../fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'), 
        url('../../fonts/Inter/Inter-Regular.woff') format('woff'), 
        url('../../fonts/Inter/Inter-Regular.ttf') format('truetype'), 
        url('../../fonts/Inter/Inter-Regular.svg#SFUFutura') format('svg');
    font-weight: normal;
}
@font-face {
    font-family: 'Inter';
    src:url('../../fonts/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'), 
        url('../../fonts/Inter/Inter-Medium.woff') format('woff'), 
        url('../../fonts/Inter/Inter-Medium.ttf') format('truetype'), 
        url('../../fonts/Inter/Inter-Medium.svg#SFUFutura') format('svg');
    font-weight: 500;
}