/*
@import "partials/fonts",
"partials/reset",
"partials/mixin",

"partials/vars",
"partials/helper",
"partials/footer",
"partials/header";
@import "pages/home.scss";
*/

@import "partials/fonts";

.select-custom {
    position: relative;
    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-shadow: none;
        padding-right: 25px !important;
    }
    
    &:before {
        content: "\f107";
        font-family: "FontAwesome";
        position: absolute;
        width: 0px;
        top: 8px;
        right: 20px;
        pointer-events: none;
        height: 0px;

        color: #666;
    }
}
.clearfix{
    padding-top: 10px!important;
    clear: both;
}

.langVN
{
    background-image: url("./../images/flags/vietnam.png");
    background-repeat: no-repeat;
    width: 29px;
    height: 17px;
}
.langEN
{
    background-image: url("./../images/flags/england.png");
    background-repeat: no-repeat;
    width: 29px;
    height: 17px;
}


.w100 {
    width: 100px !important;
}



.w200 {
    width: 200px !important;
}

.w300 {
    width: 300px !important;
}

.w400 {
    width: 400px !important;
}

.w500 {
    width: 500px !important;
}

.w600 {
    width: 600px !important;
}

.w700 {
    width: 700px !important;
}

.w800 {
    width: 800px !important;
}

.w900 {
    width: 900px !important;
}

.w1000 {
    width: 1000px !important;
}

.w5 {
    width: 5px !important;
}

.w10 {
    width: 10px !important;
}

.w15 {
    width: 15px !important;
}

.w20 {
    width: 20px !important;
}

.w25 {
    width: 25px !important;
}

.w30 {
    width: 30px !important;
}

.w35 {
    width: 35px !important;
}

.w40 {
    width: 40px !important;
}

.w45 {
    width: 45px !important;
}

.w50 {
    width: 50px !important;
}

.w60 {
    width: 60px !important;
}

.w70 {
    width: 70px !important;
}

.w80 {
    width: 80px !important;
}

.w90 {
    width: 90px !important;
}

.w10p {
    width: 10% !important;
}

.w20p {
    width: 20% !important;
}

.w30p {
    width: 30% !important;
}

.w40p {
    width: 40% !important;
}

.w50p {
    width: 50% !important;
}

.w60p {
    width: 60% !important;
}

.w70p {
    width: 70% !important;
}

.w80p {
    width: 80% !important;
}

.w90p {
    width: 90% !important;
}

.w100p {
    width: 100% !important;
}

.w120 {
    width: 120px !important;
}

.w150 {
    width: 150px !important;
}


.h100 {
    height: 100px !important;
}

.h200 {
    height: 200px !important;
}

.h300 {
    height: 300px !important;
}

.h400 {
    height: 400px !important;
}

.h500 {
    height: 500px !important;
}

.h600 {
    height: 600px !important;
}

.h700 {
    height: 700px !important;
}

.h800 {
    height: 800px !important;
}

.h900 {
    height: 900px !important;
}

.h1000 {
    height: 1000px !important;
}

.uppercase {
    text-transform: uppercase;
}

.btn-u {
    text-transform: uppercase;
}

.table td, .table th {
    padding: .45rem!important;
}

table.loading {
    position: relative;
}
table.loading:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);  background-position: center;
    background-repeat: no-repeat;
    background-size: 80px 80px;
    content: "";
    background-image: url("../img/loading/Infinity-2s-437px.gif");
}

.control-label span{
    color: red;
    font-style: italic;
}
.center {
    text-align: center!important;
}

.anticon {
    vertical-align: 0px!important;
}

.tolower{
    text-transform: lowercase;
}
.toupper{
    text-transform: uppercase;
}

.ant-form-item {
    margin-bottom: 10px!important;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    min-height: 40px;
    height: auto!important;
}
.ant-menu-item, .ant-menu-submenu-title {
    white-space: normal!important;
}
.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px!important;
}

.w100p{
    width: 100%;
}

.ant-btn-primary {
    color: #fff;
    background: #1269db;
    border-color: #1269db;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.color_0 {
    background-image: linear-gradient(to right, #00acc1, #52afbb);
  }
  
  .color_1 {
    background-image: linear-gradient(to right, #e57373, #e68a8a);
  }
  
  .color_2 {
    background-image: linear-gradient(to right, #81c784, #92c295);
  }
  .color_3 {
    background-image: linear-gradient(to right, #ffb74d, #f7c985);
  }
  
  .color_4 {
    background-image: linear-gradient(to right, #267bcf, #72a0ce);
  }
  
  .color_5 {
    background-image: linear-gradient(to right, #009688, #35837b);
  }
  
  .color_6 {
    background-image: linear-gradient(to right, #ffa726, #f7b759);
  }
  
  .ant-table-thead > tr > th {
    font-weight: 700!important;
}
.ant-input[disabled], .ant-picker-input > input[disabled], .ant-radio-disabled + span {
    color: #000!important;
}


.main-panel-full{
    width: 100%!important;
}
.content-full {
    margin-top: 0!important;
}

.sidebar .nav-collapse, .sidebar[data-background-color=white] .nav-collapse {
    margin-top: 0;
    padding-bottom: 10px;
    margin-bottom: 0px;
}

.sidebar .nav>.nav-item a p, .sidebar[data-background-color=white] .nav>.nav-item a p {
    color: #000000;
}

.sidebar .nav>.nav-item a, .sidebar[data-background-color=white] .nav>.nav-item a {
    color: #000000;
}
.sidebar .user .info a>span .user-level, .sidebar[data-background-color=white] .user .info a>span .user-level {
    font-weight: 100;
}
.sidebar .user .info a>span, .sidebar[data-background-color=white] .user .info a>span {
    font-weight: 500;
    color: #222121;
}
#search-nav {
    flex: 1;
    max-width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}
.logo-header .logo .navbar-brand {
    height: 40px;
    width: 40px;
    border-radius: 5px;
}

.logo-header {
    line-height: 60px;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    height: 65px!important;
}

.inline{
    float: left;
    line-height: 30px;
    margin-right: 15px;
}
body{
    color: #000;
    font-size: 13px;
}
.breadcrumbs {
    border-left: none;
    margin-left: 0px;
    padding-left: 0px;
}
.breadcrumbs li a {
    color: #575962;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

// .sidebar
// {
//     width: 280px!important;
// }
// .sidebar_minimize .sidebar
// {
//     width: auto!important;
// }
// @media screen and (max-width: 991px)
// {
//     .sidebar_minimize .sidebar
//     {
//         width: 75px!important;
//     }
// }
// .sidebar, .sidebar[data-background-color=white] {
//     width: 280px;
// }
// .main-panel {
//     width: calc(100% - 280px);
// }
// .logo-header {
//     width: 280px;
// }
// .table-result{
//     tbody{
//         tr:nth-child(2n+2){
//             background: #f0f0f0;
//         }
//     }
// }
@media screen and (max-width: 991px)
{
    .main-panel {
        width: 100%!important;
    }
    .main-panel .content {
        padding: 10px!important;
    }
    .page-inner {
        padding-right: 0px!important;
        padding-left: 0px!important;
        padding-top: 0px!important;
    }
    .pull-left{
        display: none;
    }
    .listDesktop{
        display: none;
    }
    .listMobile{
        display: block!important;
    }
}

.avatar-sm{
    float: left;
    width: auto!important;
    img{
        height: 2.5rem;
        width: 2.5rem;
    }
}

.name-sm{
    color: #fff!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 40px;
}
@media screen and (max-width: 991px)
{
    .name-sm{
        display: inline!important;
    }
}
.main-panel>.content {
    min-height: calc(100% - 115px);
}
.sidebar .nav-collapse li a, .sidebar[data-background-color=white] .nav-collapse li a {
    padding: 5px 25px!important;
}

.sidebar .sidebar-wrapper, .sidebar[data-background-color=white] .sidebar-wrapper {
    max-height: calc(100% - 30px);
    padding-bottom: 20px;
}

.filter td
{
    border-bottom: 1px solid #f0f0f0;
    background-color: #fafafa;
}

.custom-template .custom-toggle {
    background: #1572e8;
}

.custom-template .title {
    background: #1572e8;
}

.float-left{
    float: left!important;
}
.float-left label{
    float: left;
}
.ant-form-item-label > label
{
    font-weight: 600;
    font-size: 13px!important;
}
.ant-form-item-label{
    text-align: left!important;
}
.card-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px!important;
    margin: 0!important;
    line-height: 2;
}

.breadcrumbs li.separator {
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.content-list-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px!important;
    font-weight: 600;
    padding-bottom: 5px;
    padding-top: 5px;
}

.search-padding
{
    padding-top: 32px;
}
.search-padding button
{
    margin-bottom: 5px;
}
.card .card-body, .card-light .card-body {
   padding: 10px!important;
}
.page-inner {
    padding: 1rem 0;
}
@media (min-width: 992px)
{
    .page-inner {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.card-tools button{
    margin-bottom: 5px;
}

.card-tools .icon
{
    margin-right: 20px;
}

.card-tools button i{
    padding-right: 5px;
}

.search-padding button i{
    padding-right: 5px;
}
.ant-modal-footer i{
    padding-right: 5px;
}

.switch-block i{
    padding-right: 5px;
}
.ant-btn i{
    padding-right: 5px;
}
.table-result i{
    padding-right: 0px;
}

.ant-tree button i, .ant-table  button i{
    padding-right: 0px;
}
.control-label{
    font-weight: 600;
}
.heightRow
{
    padding-bottom: 6px;
}

.footer {
    font-size: 14px!important;
}

.main-header{
    height: 65px!important;
    min-height: 65px!important;
    // position: absolute!important;
    z-index: 1000;
}
.main-header .navbar-header {
    min-height: 65px!important;    
    width: 100%;
    padding-left: 255px;
}
.sidebar_minimize .main-header .navbar-header {
    padding-left: 0px;
}
// .sidebar, .sidebar
// {
//     position: absolute!important;
// }
.navbar {
    position: absolute!important;
}
@media screen and (max-width: 991px)
{
    .sidebar {
        margin-top: 0px!important;
    }
    .main-header .navbar-header {
        padding-left: 0px;
    }
}

.ant-table.ant-table-small {
    font-size: 13px!important;
}

@media screen and (min-width: 991px)
{
    .sidebar_minimize .main-panel {
        width: calc(100% - 0px);
    }
    .sidebar_minimize .sidebar {
        display: none;
    }
    .sidebar_minimize .logo-header {
        display: none;
    }
    .main-header .navbar-header .btn-toggle {
        margin-right: 10px;
        display: block!important;
    }
    .sidebar {
        margin-top: 66px!important;
    }
}
.main-header .navbar-header .btn-toggle {
    margin-right: 10px;
    display: none;
}
.sidebar {
    margin-top: 0px;
}

.colBT{
    padding: 5px 0;
}
.card .card-header, .card-light .card-header {
    padding: 5px 10px!important;
}

.sidebar .nav>.nav-item a p, .sidebar .nav>.nav-item a p {
    font-size: 13px!important;
}
.sidebar .nav-collapse li a .sub-item, .sidebar .nav-collapse li a .sub-item {
    
    font-size: 13px!important;
}
.sidebar .user .info a>span, .sidebar[data-background-color=white] .user .info a>span {
    font-size: 13px!important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: ''!important;
    margin-right: 0!important;
}

span.required{
    padding-left: 5px;
    color: red;
    font-weight: normal;
    font-style: italic;
}

.logo-header[data-background-color=dtzsoft1] {
	background: #68C39F!important
}
.navbar-header[data-background-color=dtzsoft1] {
    background: #68C39F!important;
}
.sidebar[data-background-color=dtzsoft1].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #68C39F !important;
}
.sidebar[data-background-color=dtzsoft1] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft1].nav>.nav-item a i {
    color: #68C39F !important;
}
.sidebar[data-background-color=dtzsoft1] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft1] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft1] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft1] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #68C39F !important;
}
.sidebar[data-background-color=dtzsoft1] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft1] .nav.nav-primary > .nav-item.active a i {
    color: #68C39F  !important;
}
.sidebar[data-background-color=dtzsoft1].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft1] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft1] .ant-btn-primary {
    color: #fff;
    background: #5CAD8D;
    border-color: #5CAD8D;
}
body[data-background-color=dtzsoft1] .ant-btn-primary:hover {
    color: #fff;
    background: #68C39F;
    border-color: #68C39F;
}
body[data-background-color=dtzsoft1] .ant-dropdown-menu .fa{
    color: #5CAD8D !important;
}
.card, .card-light {
    border: 1px solid rgba(0,0,0,.125)!important;
}
.card .card-header, .card-light .card-header {
    border-bottom: 1px solid rgba(0,0,0,.125)!important;
}
.ant-table-thead > tr > th{
    background: #f2f2f2!important;
}
.ant-table table {
    border: solid 1px rgba(0,0,0,.125)!important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
    border-top: 1px solid rgba(0,0,0,.125)!important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
    border-right: 1px solid rgba(0,0,0,.125)!important;
}
.ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(0,0,0,.125)!important;
}
.ant-table-thead > tr > th {
    border-bottom: 1px solid rgba(0,0,0,.125)!important;
}
.ant-table {
    color: #000!important;
}
.sidebar, .sidebar {
    border-right: solid 1px rgba(0,0,0,.125);
}
.sidebar .user, .sidebar[data-background-color=white] .user {
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.sidebar .user .info a > span, .sidebar[data-background-color=white] .user .info a > span {
    color: #000;
}
.sidebar .user .info a>span .user-level, .sidebar[data-background-color=white] .user .info a>span .user-level {
    color: #000;
}
.btnSwitch button[data-color="dtzsoft1"] {
    background-color: #68C39F;
}
.btnSwitch button[data-color="dtzsoft2"] {
    background-color: #1f283e;
} 
.btnSwitch button[data-color="dtzsoft3"] {
    background-color: #1269DB;
}
.btnSwitch button[data-color="dtzsoft4"] {
    background-color: #5C55BF;
}
.btnSwitch button[data-color="dtzsoft5"] {
    background-color: #3697E1;
}
.btnSwitch button[data-color="dtzsoft6"] {
    background-color: #2BB930;
}
.btnSwitch button[data-color="dtzsoft7"] {
    background-color: #FF9E27;
}
.btnSwitch button[data-color="dtzsoft8"] {
    background-color: #EA4d56;
}

/*//////////////////*/
.logo-header[data-background-color=dtzsoft2] {
	background: #1f283e!important
}
.navbar-header[data-background-color=dtzsoft2] {
    background: #1f283e!important;
}
.sidebar[data-background-color=dtzsoft2].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #1f283e !important;
}
.sidebar[data-background-color=dtzsoft2] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft2].nav>.nav-item a i {
    color: #1f283e !important;
}
.sidebar[data-background-color=dtzsoft2] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft2] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft2] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft2] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #1f283e !important;
}
.sidebar[data-background-color=dtzsoft2] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft2] .nav.nav-primary > .nav-item.active a i {
    color: #1f283e  !important;
}
.sidebar[data-background-color=dtzsoft2].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft2] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft2] .ant-btn-primary {
    color: #fff;
    background: #1f283e;
    border-color: #1f283e;
}
body[data-background-color=dtzsoft2] .ant-btn-primary:hover {
    color: #fff;
    background: #1f283e;
    border-color: #1f283e;
}
body[data-background-color=dtzsoft2] .ant-dropdown-menu .fa{
    color: #1f283e !important;
}

/*//////////////////*/
.logo-header[data-background-color=dtzsoft3] {
	background: #1269DB!important
}
.navbar-header[data-background-color=dtzsoft3] {
    background: #1269DB!important;
}
.sidebar[data-background-color=dtzsoft3].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #1269DB !important;
}
.sidebar[data-background-color=dtzsoft3] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft3].nav>.nav-item a i {
    color: #1269DB !important;
}
.sidebar[data-background-color=dtzsoft3] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft3] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft3] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft3] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #1269DB !important;
}
.sidebar[data-background-color=dtzsoft3] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft3] .nav.nav-primary > .nav-item.active a i {
    color: #1269DB  !important;
}
.sidebar[data-background-color=dtzsoft3].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft3] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft3] .ant-btn-primary {
    color: #fff;
    background: #1269DB;
    border-color: #1269DB;
}
body[data-background-color=dtzsoft3] .ant-btn-primary:hover {
    color: #fff;
    background: #1269DB;
    border-color: #1269DB;
}
body[data-background-color=dtzsoft3] .ant-dropdown-menu .fa{
    color: #1269DB !important;
}

/*//////////////////*/
.logo-header[data-background-color=dtzsoft4] {
	background: #5C55BF!important
}
.navbar-header[data-background-color=dtzsoft4] {
    background: #5C55BF!important;
}
.sidebar[data-background-color=dtzsoft4].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #5C55BF !important;
}
.sidebar[data-background-color=dtzsoft4] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft4].nav>.nav-item a i {
    color: #5C55BF !important;
}
.sidebar[data-background-color=dtzsoft4] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft4] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft4] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft4] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #5C55BF !important;
}
.sidebar[data-background-color=dtzsoft4] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft4] .nav.nav-primary > .nav-item.active a i {
    color: #5C55BF  !important;
}
.sidebar[data-background-color=dtzsoft4].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft4] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft4] .ant-btn-primary {
    color: #fff;
    background: #5C55BF;
    border-color: #5C55BF;
}
body[data-background-color=dtzsoft4] .ant-btn-primary:hover {
    color: #fff;
    background: #5C55BF;
    border-color: #5C55BF;
}
body[data-background-color=dtzsoft4] .ant-dropdown-menu .fa{
    color: #5C55BF !important;
}

/*//////////////////*/
.logo-header[data-background-color=dtzsoft5] {
	background: #3697E1!important
}
.navbar-header[data-background-color=dtzsoft5] {
    background: #3697E1!important;
}
.sidebar[data-background-color=dtzsoft5].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #3697E1 !important;
}
.sidebar[data-background-color=dtzsoft5] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft5].nav>.nav-item a i {
    color: #3697E1 !important;
}
.sidebar[data-background-color=dtzsoft5] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft5] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft5] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft5] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #3697E1 !important;
}
.sidebar[data-background-color=dtzsoft5] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft5] .nav.nav-primary > .nav-item.active a i {
    color: #3697E1  !important;
}
.sidebar[data-background-color=dtzsoft5].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft5] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft5] .ant-btn-primary {
    color: #fff;
    background: #3697E1;
    border-color: #3697E1;
}
body[data-background-color=dtzsoft5] .ant-btn-primary:hover {
    color: #fff;
    background: #3697E1;
    border-color: #3697E1;
}
body[data-background-color=dtzsoft5] .ant-dropdown-menu .fa{
    color: #3697E1 !important;
}

/*//////////////////*/
.logo-header[data-background-color=dtzsoft6] {
	background: #2BB930!important
}
.navbar-header[data-background-color=dtzsoft6] {
    background: #2BB930!important;
}
.sidebar[data-background-color=dtzsoft6].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #2BB930 !important;
}
.sidebar[data-background-color=dtzsoft6] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft6].nav>.nav-item a i {
    color: #2BB930 !important;
}
.sidebar[data-background-color=dtzsoft6] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft6] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft6] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft6] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #2BB930 !important;
}
.sidebar[data-background-color=dtzsoft6] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft6] .nav.nav-primary > .nav-item.active a i {
    color: #2BB930  !important;
}
.sidebar[data-background-color=dtzsoft6].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft6] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft6] .ant-btn-primary {
    color: #fff;
    background: #2BB930;
    border-color: #2BB930;
}
body[data-background-color=dtzsoft6] .ant-btn-primary:hover {
    color: #fff;
    background: #2BB930;
    border-color: #2BB930;
}
body[data-background-color=dtzsoft6] .ant-dropdown-menu .fa{
    color: #2BB930 !important;
}

/*//////////////////*/
.logo-header[data-background-color=dtzsoft7] {
	background: #FF9E27!important
}
.navbar-header[data-background-color=dtzsoft7] {
    background: #FF9E27!important;
}
.sidebar[data-background-color=dtzsoft7].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #FF9E27 !important;
}
.sidebar[data-background-color=dtzsoft7] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft7].nav>.nav-item a i {
    color: #FF9E27 !important;
}
.sidebar[data-background-color=dtzsoft7] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft7] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft7] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft7] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #FF9E27 !important;
}
.sidebar[data-background-color=dtzsoft7] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft7] .nav.nav-primary > .nav-item.active a i {
    color: #FF9E27  !important;
}
.sidebar[data-background-color=dtzsoft7].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft7] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft7] .ant-btn-primary {
    color: #fff;
    background: #FF9E27;
    border-color: #FF9E27;
}
body[data-background-color=dtzsoft7] .ant-btn-primary:hover {
    color: #fff;
    background: #FF9E27;
    border-color: #FF9E27;
}
body[data-background-color=dtzsoft7] .ant-dropdown-menu .fa{
    color: #FF9E27 !important;
}

/*//////////////////*/
.logo-header[data-background-color=dtzsoft8] {
	background: #EA4d56!important
}
.navbar-header[data-background-color=dtzsoft8] {
    background: #EA4d56!important;
}
.sidebar[data-background-color=dtzsoft8].sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
    background: #EA4d56 !important;
}
.sidebar[data-background-color=dtzsoft8] .nav>.nav-item a i, 
.sidebar[data-background-color=dtzsoft8].nav>.nav-item a i {
    color: #EA4d56 !important;
}
.sidebar[data-background-color=dtzsoft8] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, 
.sidebar[data-background-color=dtzsoft8] .nav.nav-primary > .nav-item a:focus i, 
.sidebar[data-background-color=dtzsoft8] .nav.nav-primary > .nav-item a:hover i, 
.sidebar[data-background-color=dtzsoft8] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i{
    color: #EA4d56 !important;
}
.sidebar[data-background-color=dtzsoft8] .nav.nav-primary > .nav-item.active a i, 
.sidebar[data-background-color=dtzsoft8] .nav.nav-primary > .nav-item.active a i {
    color: #EA4d56  !important;
}
.sidebar[data-background-color=dtzsoft8].sidebar-style-2 .nav.nav-primary>.nav-item.active>a i{
    color: #fff!important;
}
.sidebar[data-background-color=dtzsoft8] .nav > .nav-item a {
    color: #000000;
}
body[data-background-color=dtzsoft8] .ant-btn-primary {
    color: #fff;
    background: #EA4d56;
    border-color: #EA4d56;
}
body[data-background-color=dtzsoft8] .ant-btn-primary:hover {
    color: #fff;
    background: #EA4d56;
    border-color: #EA4d56;
}
body[data-background-color=dtzsoft8] .ant-dropdown-menu .fa{
    color: #EA4d56 !important;
}

.custom-template[data-background-color="dtzsoft1"] .title{
    background-color: #68C39F;
}
body[data-background-color=dtzsoft1] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #68C39F;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #68C39F;
    }
}

.custom-template[data-background-color="dtzsoft2"] .title{
    background-color: #1f283e;
} 
body[data-background-color=dtzsoft2] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #1f283e;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #1f283e;
    }
}

.custom-template[data-background-color="dtzsoft3"] .title{
    background-color: #1269DB;
}
body[data-background-color=dtzsoft3] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #1269DB;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #1269DB;
    }
}

.custom-template[data-background-color="dtzsoft4"] .title{
    background-color: #5C55BF;
}
body[data-background-color=dtzsoft4] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #5C55BF;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #5C55BF;
    }
}

.custom-template[data-background-color="dtzsoft5"] .title{
    background-color: #3697E1;
}
body[data-background-color=dtzsoft5] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #3697E1;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #3697E1;
    }
}

.custom-template[data-background-color="dtzsoft6"] .title{
    background-color: #2BB930;
}
body[data-background-color=dtzsoft6] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #2BB930;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #2BB930;
    }
}

.custom-template[data-background-color="dtzsoft7"] .title{
    background-color: #FF9E27;
}
body[data-background-color=dtzsoft7] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #FF9E27;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #FF9E27;
    }
}

.custom-template[data-background-color="dtzsoft8"] .title{
    background-color: #EA4d56;
}
body[data-background-color=dtzsoft8] {
    background: #f9fbfd;
    .ant-modal-header{
        background-color: #EA4d56;
        color: #fff;
        .ant-modal-title{
            color: #fff;
        }
        .ant-modal-close{
            color: #fff!important;
        }
    }
    .widget{
        background-color: #EA4d56;
    }
}
.ant-tree {
    font-size: 13px!important;
}

.sidebar .user .info a, .sidebar .user .info a {
    text-decoration: unset!important;
}